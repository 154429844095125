@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New"; */
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

td {
  border: "1px solid black" !important;
}
main {
  min-height: 80vh;
}
a {
  text-decoration: none;
  color: "inherit";
}
::-webkit-scrollbar {
  width: 8.5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #115c8e, #f4829d) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #115c8e, #f4829d) !important;
  cursor: pointer;
}
